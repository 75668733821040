import Rom from '@organisms/ORom';
import { TOnBoardSymptomsProps } from '@stores/interfaces';
import { Button } from 'antd';
import './RangeOfMotion.css';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { createOnBoardSession } from '@stores/rom/main';
import { useState } from 'react';
import { setActiveStep as StoresActiveStep } from '@stores/onBoard/onBoard';
import { CompletionScreen } from '@organisms/ORom/CompletionScreen/CompletionScreen';
import AiAssistantCustomRomScanResult from '@pages/AiAssistantCustomRomScanResult';

const RangeOfMotion = (props: TOnBoardSymptomsProps) => {
	const { setActiveStep, setProgressPercent } = props;
	const { t } = useTranslation();
	const [scanState, setScanState] = useState(false);
	const videoRecordState = useTypedSelector(
		state => state.onBoard.onBoard.videoRecordState,
	);
	const user = useTypedSelector(state => state.user);
	const dispatch = useTypedDispatch();
	const onBoardRomCompletion = useTypedSelector(
		state => state.onBoard.onBoard.onBoardRomCompletion,
	);
	const handleFetch = async () => {
		await dispatch(
			createOnBoardSession({
				userId: user?.id,
			}),
		);
	};
	const buttonStyle = {
		color: '#533F85',
		border: 'inherit',
		width: '100%',
		height: '50px',
		marginTop: '26px',
		marginBottom: '6px',
	};

	return (
		<div className="rom-medical-container">
			{!scanState ? (
				<>
					<div className="rom-main-container">
						<div className="rom-inner-container">
							<div className="rom-card">
								<img
									className="rom-image"
									src="/images/dashboard/omnirom-dashboard.png"
									alt="Range of Motion"
									style={{ width: '42%' }}
								/>
								<div className="rom-info">
									<div style={{ height: '40px' }}>
										<img src="/images/dashboard/omnirom-logo.svg" alt="Logo" />
									</div>
									<p className="rom-text">
										{t('Patient.data.dashboardScreen.romMeasures')}
										<div className="rom-btn-container">
											<Button
												className="start-session-css"
												style={buttonStyle}
												onClick={e => {
													e.stopPropagation();
													setScanState(true);
													dispatch(StoresActiveStep(8));
													handleFetch();
												}}>
												<span className="rom-btn-text">
													{t('Patient.data.dashboardScreen.mobilityScore')}
												</span>
											</Button>
										</div>
									</p>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					{!onBoardRomCompletion ? (
						<>
							<div className="header-container-rom">
								<h1 className="header-title">
									{t('Patient.data.onboard.rangeMotion')}
								</h1>
								<h3 className="header-subtitle">
									{t('Patient.data.onboard.scanRange')}
								</h3>
							</div>
							<div className="rom-container">
								<Rom isDashboard={true} />
							</div>
						</>
					) : (
						<AiAssistantCustomRomScanResult isDashboard={true}/>
					)}
				</>
			)}
			{(!scanState || onBoardRomCompletion) && (
				<div
					className={`rom-omni-button-container ${videoRecordState ? 'rom-opacity' : ''}`}>
					<Button
						className="rom-back-btn"
						disabled={videoRecordState}
						onClick={() => {
							setActiveStep!(7);
						}}
						size="large"
						type="primary"
						block
						style={{ height: '48px', width: '343px' }}>
						{t('Patient.data.onboard.back')}
					</Button>
					<Button
						size="large"
						className="rom-skip-btn"
						disabled={videoRecordState}
						type="primary"
						block
						style={{
							height: '48px',
							background: '#6941C6',
							color: '#fff',
							width: '343px',
						}}
						onClick={() => {
							setActiveStep!(9);
							setProgressPercent(90);
						}}>
						{t(onBoardRomCompletion ? 'Patient.data.onboard.next' : 'Patient.data.onboard.skip')}
					</Button>
				</div>
			)}
		</div>
	);
};

export default RangeOfMotion;
