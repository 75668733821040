import Calibration from '@atoms/APTransitionCalibration';
import Closing from '@atoms/APTransitionClosing';
import Openning from '@atoms/APTransitionOpenning';
import ReadySetGo from '@atoms/APTransitionReadySetGo';

import { useCallback, useEffect, useRef, useState } from 'react';

import SingleResult from '@molecules/MPTransitionSingleResultRom';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	closeSession,
	ETransitions,
	getPrintScreen,
	nextExercise,
	nextTransition,
	setMetricsDataValue,
	setResultExerciseValidateValue,
} from '@stores/rom/main';
import { useNavigate } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import InstructionalVideo from './InstructionalVideo';
import { ExerciseTransitionTime } from './TransitionTime';
import { ResultScreen } from './ResultScreen';
import { setShowAllResults } from '@stores/rom/results';
import { router } from '@routers/routers';
import { setOnBoardRomCompletion } from '@stores/onBoard/onBoard';
import {
	getRomSessionById,
} from '@stores/rom/customRom';

const legMovementsExerciseIds = [16, 17, 18, 19, 20, 21, 22, 23];

interface IMainProps {
	isDashboard: boolean;
	transitionTime: number;
	setTransitionTime: (value: number) => void;
	children: JSX.Element | JSX.Element[];
	isVideoPause: boolean;
	onTogglePauseVideo: () => void;
	isSwitchMode: boolean;
	isFullscreen: boolean;
	isCompleted: boolean;
	setIsManual: (val: boolean) => void;
	setCompleted: (val: boolean) => void;
	onSavePhysicalAssessmentsMetrics: () => void;
	isManual: boolean;
}

const Main = (props: IMainProps) => {
	const {
		children,
		isSwitchMode,
		isFullscreen,
		isVideoPause,
		onTogglePauseVideo,
		isCompleted,
		setIsManual,
		isManual,
		setCompleted,
		onSavePhysicalAssessmentsMetrics,
		transitionTime,
		setTransitionTime,
		isDashboard,
	} = props;
	const screenshotSound = '/assets/screenshotSound.mp3';
	const dispatch = useTypedDispatch();
	const navigate = useNavigate();
	const {
		user,
		isCustom,
		currentExercise,
		transition,
		finishedExercises,
		paused,
		uploadProgress,
		enableToSendResult,
		pose,
		isRepetingExercise,
		resultExerciseValidation,
		bodyPointsVisible,
		session,
		metricsData,
	} = useTypedSelector(state => state.rom.main);
	const [savedVoice, setSavedVoice] = useState('');
	const transitionRef = useRef(transition);
	const isIpad =
		/iPad|Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1;

	useEffect(() => {
		transitionRef.current = transition;
	}, [transition]);

	const onNextTransition = useCallback(() => {
		dispatch(nextTransition(transition?.next));
	}, [dispatch, transition]);

	const onNextExercise = () => dispatch(nextExercise());

	const customRomSession = useTypedSelector(state => state.rom.main.session);

	const onSavePhysicalAssessmentsMetricsToState = useCallback(async () => {
		const data = {
			userId: user?.id,
			strapiOmniRomExerciseId: currentExercise?.id,
			romSessionId: session?.id,
			value: isCustom ? pose.angleResults : +pose.angleResult,
			coordinates: isCustom ? pose.multiCoordinates : pose.coordinates,
			screenshot: await getPrintScreen(),
		};
		dispatch(setMetricsDataValue(data));
		dispatch(setResultExerciseValidateValue(data));
		const sound = new Audio(screenshotSound);
		if (sound && !isIpad) {
			sound.play();
		}
	}, [user, pose, currentExercise, session, dispatch]);

	useEffect(() => {
		if (finishedExercises?.finished && session?.id) {
			setCompleted(true);
			dispatch(setOnBoardRomCompletion(true));
		}
	}, [finishedExercises?.finished, session?.id, navigate]);

	useEffect(() => {
		if (transition?.value === ETransitions.CLOSING) {
			onSavePhysicalAssessmentsMetricsToState();
		}
	}, [transition]);

	useEffect(() => {
		if (
			transition?.value === ETransitions.RESULT &&
			metricsData?.screenshot &&
			!isManual
		) {
			onSavePhysicalAssessmentsMetrics();
		}
	}, [enableToSendResult, transition, metricsData]);

	useEffect(() => {
		if (transition?.value === ETransitions.CALIBRATION) {
			setTimeout(() => {
				onSavePhysicalAssessmentsMetricsToState();
			}, 3000);
		}
	}, [bodyPointsVisible]);

	const removeAllCanvases = () => {
		// Find all canvas elements (on-screen)
		const canvases = document.querySelectorAll('canvas');

		canvases.forEach(canvasEl => {
			const canvas = canvasEl as HTMLCanvasElement;

			// Clear 2D context
			const ctx2d = canvas.getContext('2d');
			if (ctx2d) {
				ctx2d.clearRect(0, 0, canvas.width, canvas.height);
			}

			// Dispose WebGL context
			const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
			if (gl) {
				const loseCtx = gl.getExtension('WEBGL_lose_context');
				if (loseCtx) {
					loseCtx.loseContext();
				}
			}

			canvas.width = 0;
			canvas.height = 0;
			canvas.remove();
		});

		// Extra: look for global/offscreen canvas references and delete them manually
		Object.keys(window).forEach(key => {
			if (
				(window as any)[key] instanceof HTMLCanvasElement ||
				((window as any)[key]?.getContext &&
					typeof (window as any)[key].getContext === 'function')
			) {
				console.log('Clearing leaked canvas from window property:', key);
				(window as any)[key] = null;
			}
		});

		console.log('✅ All canvas and context cleanup attempted.');
	};

	const completeSession = async () => {
		await dispatch(closeSession(customRomSession?.id!));
	};

	const stopAllMediaStreams = () => {
		const videos = document.querySelectorAll('video');
		videos.forEach(video => {
			const stream = video.srcObject as MediaStream | null;
			if (stream) {
				stream.getTracks().forEach(track => track.stop());
				video.srcObject = null;
			}
		});
		console.log('🎥 All media streams stopped.');
	};

	useEffect(() => {
		handleCompletion()
	}, [isCompleted]);

	const handleCompletion = async () => {
		if (isCompleted) {
			removeAllCanvases();
			stopAllMediaStreams();
			completeSession();
			if (isIpad) {
				sessionStorage.removeItem('freshEntry');
			}
			await dispatch(getRomSessionById({ customRomId: session?.id! }));
			if (!isDashboard) {
				navigate(`/${user?.id}${router.AIASSISTANT_ROM_SCAN_RESULT}`);
			} else dispatch(setOnBoardRomCompletion(true))
			dispatch(setShowAllResults(false));
		}
	}

	return (
		<Content className="h-full overflow-hidden" style={{ aspectRatio: '4/2' }}>
			{!transitionTime && !isManual && (
				<ExerciseTransitionTime
					setIsManual={setIsManual}
					setSavedVoice={setSavedVoice}
					savedVoice={savedVoice}
					setTransitionTime={setTransitionTime}
					transitionTime={transitionTime!}
				/>
			)}

			{!isCompleted &&
				transitionTime &&
				!finishedExercises.finished &&
				!resultExerciseValidation?.screenshot &&
				transition?.value != ETransitions.RESULT && (
					<InstructionalVideo
						isVideoPause={isVideoPause}
						onTogglePauseVideo={onTogglePauseVideo}
						onNextTransition={onNextTransition}
						transition={transition}
						currentExercise={currentExercise!}
						isSwitchMode={isSwitchMode}
						isFullscreen={isFullscreen}
						isRepeat={isRepetingExercise}
					/>
				)}

			{!isCompleted &&
				!bodyPointsVisible &&
				transition?.value === ETransitions.CALIBRATION && (
					<Calibration
						label={
							legMovementsExerciseIds.includes(currentExercise?.id)
								? 'STEP BACK, and Kindly use a chair for support and ensure your ankles are visible.'
								: 'STEP BACK'
						}
					/>
				)}

			{/* {isCompleted && isDashboard && (
				<CompletionScreen savedVoice={savedVoice} />
			)} */}

			{transition?.value === ETransitions.READYSETGO && (
				<ReadySetGo
					isPaused={paused}
					onNextTransition={onNextTransition}
					transitionTime={transitionTime!}
				/>
			)}
			{transition?.value === ETransitions.CLOSING && (
				<Closing isPaused={paused} onNextTransition={onNextTransition} />
			)}

			{transition?.value === ETransitions.RESULT &&
				(isManual ? (
					<div
						style={{
							width: '100%',
							height: '100%',
							background: '#6941C6D9',
						}}>
						<span
							style={{
								width: '100%',
								position: 'absolute',
								display: 'flex',
								justifyContent: 'center',
								top: 10,
								left: 0,
								color: 'white',
								zIndex: 10,
								fontWeight: '700',
								fontSize: 30,
							}}>
							{(currentExercise?.name
								? currentExercise?.name
								: currentExercise?.title
							)?.toUpperCase()}
						</span>
						{uploadProgress > 0 ? (
							<SingleResult
								exerciseName={
									currentExercise?.name
										? currentExercise?.name
										: currentExercise?.title!
								}
								isPaused={paused}
								enableToSendResult={enableToSendResult}
								onNextTransition={onNextTransition}
								resultExerciseValidation={resultExerciseValidation}
							/>
						) : (
							<ResultScreen screenshot={metricsData?.screenshot} />
						)}
					</div>
				) : (
					<SingleResult
						exerciseName={
							currentExercise?.name
								? currentExercise?.name
								: currentExercise?.title!
						}
						isPaused={paused}
						enableToSendResult={enableToSendResult}
						onNextTransition={onNextTransition}
						resultExerciseValidation={resultExerciseValidation}
					/>
				))}
			{transition?.value === ETransitions.OPENNING && (
				<Openning isPaused={paused} onNextExercise={onNextExercise} />
			)}
			{children}
		</Content>
	);
};

export default Main;
