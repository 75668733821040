import { memo, useEffect, useRef, useState } from 'react';

import '@organisms/ORom/style.css';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	ETransitions,
	TTransitions,
	clearPoseData,
	completedExercise,
	exerciseValue,
	getBodyPointsVisible,
	goToExercise,
	nextTransition,
	savePatientResults,
	setCameraId,
	updateUser,
} from '@stores/rom/main';
import { Row } from 'antd';
import RomData from './RomData';
import { NormalizedLandmark } from '@mediapipe/tasks-vision';
import { useLocation } from 'react-router-dom';
import { setOnBoardRomCompletion } from '@stores/onBoard/onBoard';

interface SaveResultsPayload {
	userId: string;
	romSessionId: string;
	title: string;
	screenshot: string;
	coordinates: string;
	results: {
		create: {}[];
	};
}

interface ORomProps {
	isDashboard?: boolean;
}

const ORom = (props: ORomProps) => {
	const { isDashboard } = props;
	const { state } = useLocation();
	const [isFullscreen, setFullscreen] = useState(false);
	const [flipCamera, setFlipCamera] = useState(false);
	const [isMenuOpened, setMenuOpen] = useState(false);
	const [isTutorialOpened, setTutorialOpen] = useState(false);
	const [isSwitchMode, setSwitchMode] = useState(false);
	const [isSplashOpened, setSplashOpen] = useState(true);
	const [isVideoPause, setVideoPause] = useState(false);
	const [isValidResult, setValidResult] = useState<boolean | null>(null);
	const [isCompleted, setCompleted] = useState(false);
	const [isManual, setIsManual] = useState(false);
	const [isInfoOpened, setInfoOpen] = useState(false);
	const fullscreenRef = useRef<HTMLDivElement>(null);
	const dispatch = useTypedDispatch();
	const {
		session,
		isCustom,
		pose,
		transition,
		metricsData,
		isRepetingExercise,
		enableToSendResult,
		exercises,
		currentExercise,
		cameraId,
	} = useTypedSelector(state => state.rom.main);

	const user = useTypedSelector(state => {
		const { selectedUser } = state.contacts.main;
		const user = state.user;
		return user.isPhysioterapist ? selectedUser : user;
	});

	const onToggleMenu = () => {
		setTutorialOpen(false);
		setMenuOpen(!isMenuOpened);
		setInfoOpen(false);
	};

	const onToggleInfo = () => {
		setInfoOpen(!isInfoOpened);
		setMenuOpen(false);
	};

	const onToggleTutorial = () => {
		setMenuOpen(false);
		setTutorialOpen(!isTutorialOpened);
	};

	const onTogglesSwitchMode = () => {
		setSwitchMode(!isSwitchMode);
	};

	const onTogglesSplashPage = () => {
		setSplashOpen(!isSplashOpened);
	};

	const onExerciseValueAndCoordinates = (
		value: number,
		coordinates: NormalizedLandmark[],
	) => {
		dispatch(exerciseValue({ value, coordinates }));
	};

	const onBodyPointsVisible = (value: boolean) => {
		dispatch(getBodyPointsVisible(value));
	};

	const switchCamera = async () => {
		try {
			// Request camera access with specific constraints
			await navigator.mediaDevices.getUserMedia({
				audio: true,
				video: { width: 1280, height: 720, frameRate: 30 },
			});

			// Get the list of video input devices
			const devices = await navigator.mediaDevices.enumerateDevices();
			const cameras = devices.filter(device => device.kind === 'videoinput');

			if (cameras.length > 0) {
				// Find the current camera index
				const currentCameraIndex = cameras.findIndex(
					camera => camera.deviceId === cameraId,
				);
				const nextCameraIndex = (currentCameraIndex + 1) % cameras.length;
				const nextCamera = cameras[nextCameraIndex];

				if (nextCamera?.deviceId) {
					dispatch(setCameraId(nextCamera.deviceId));
				} else {
					// Retry switching if deviceId is empty
					setTimeout(switchCamera, 1000);
				}
			}
		} catch (error) {
			console.error('Error switching camera:', error);
		}
	};

	const onTogglePauseVideo = () => {
		setVideoPause(!isVideoPause);
	};

	const onFullscreen = async () => {
		isFullscreen
			? await document.exitFullscreen()
			: await fullscreenRef.current!.requestFullscreen()!;
	};

	const onNextTransition = (transition: TTransitions) =>
		dispatch(nextTransition(transition));

	const exerciseNumber = exercises?.findIndex(
		exer => exer.id === currentExercise?.id,
	);

	const onSavePhysicalAssessmentsMetrics = async () => {
		dispatch(completedExercise(currentExercise));

		const payload: SaveResultsPayload = {
			userId: user?.id,
			romSessionId: session?.id!,
			title: currentExercise?.name
				? currentExercise?.name
				: currentExercise?.title!,
			screenshot: metricsData?.screenshot,
			coordinates: metricsData?.coordinates,
			results: {
				create: [],
			},
		};

		if (currentExercise?.exercises?.length) {
			currentExercise.exercises.map(async (exercise, index) => {
				const value = isCustom ? pose?.angleResults[index] : pose?.angleResult;
				payload.results.create.push({
					value: value,
					normal: exercise?.normal,
					min: exercise?.min,
					max: exercise?.max || exercise?.normal,
					wfl: exercise?.wfl,
					outOfRange: value < exercise?.min! && value > exercise?.max!,
					romProgramExerciseId: exercise?.id,
				});
			});
		}

		await dispatch(savePatientResults(payload));

		exerciseNumber === exercises?.length! - 1
			? setCompleted(true)
			: dispatch(goToExercise(exercises![exerciseNumber! + 1]));
		dispatch(clearPoseData());
	};

	useEffect(() => {
		const onFullscreenChange = () =>
			setFullscreen(Boolean(document.fullscreenElement));

		document.addEventListener('fullscreenchange', onFullscreenChange);
		return () =>
			document.removeEventListener('fullscreenchange', onFullscreenChange);
	}, []);

	useEffect(() => {
		const enumerateCameras = async () => {
			try {
				if (navigator.mediaDevices?.enumerateDevices) {
					const devices = await navigator.mediaDevices.enumerateDevices();
					const cameras = devices.filter(
						device => device.kind === 'videoinput',
					);
					setFlipCamera(cameras.length > 1);
				}
			} catch (error) {
				console.error('Error enumerating devices:', error);
			}
		};

		enumerateCameras().then(() => {
			switchCamera(); // Ensure this runs after camera detection
		});

		return () => setFlipCamera(false);
	}, []);

	useEffect(() => {
		return () => {
			setFullscreen(false);
			setFlipCamera(false);
			setMenuOpen(false);
			setTutorialOpen(false);
			setSwitchMode(false);
			setSplashOpen(true);
			setVideoPause(false);
			setValidResult(null);
			setCompleted(false);
			setIsManual(false);
			setInfoOpen(false);
		};
	}, []);

	useEffect(() => {
		dispatch(updateUser(user));
	}, [user, dispatch]);

	useEffect(() => {
		if (transition?.value === ETransitions.INTRO) {
			setVideoPause(false);
		}
	}, [transition, isRepetingExercise]);

	useEffect(() => {
		if (transition?.value === ETransitions.RESULT) {
			setValidResult(enableToSendResult);
		} else {
			setValidResult(null);
		}

		return () => setValidResult(null);
	}, [transition, enableToSendResult]);

	console.count('ORom render');

	return (
		<Row
			align={'top'}
			className="select-none"
			style={{ backgroundColor: '#fff', marginTop: '-1rem' }}
			justify="center">
			<RomData
				isSplashOpened={
					!state?.isDashboard && !isDashboard && !isCustom && isSplashOpened
				}
				onToggleMenu={onToggleMenu}
				isDashboard={isDashboard}
				isValidResult={isValidResult}
				onSavePhysicalAssessmentsMetrics={onSavePhysicalAssessmentsMetrics}
				isVideoPause={isVideoPause}
				onTogglePauseVideo={onTogglePauseVideo}
				isFullscreen={isFullscreen}
				onToggleTutorial={onToggleTutorial}
				onFullscreen={onFullscreen}
				onTogglesSwitchMode={onTogglesSwitchMode}
				flipCamera={flipCamera}
				switchCamera={switchCamera}
				isSwitchMode={isSwitchMode}
				isMenuOpened={isMenuOpened}
				isTutorialOpened={isTutorialOpened}
				onTogglesSplashPage={onTogglesSplashPage}
				onExerciseValueAndCoordinates={onExerciseValueAndCoordinates}
				onBodyPointsVisible={onBodyPointsVisible}
				onNextTransition={onNextTransition}
				fullscreenRef={fullscreenRef}
				isCompleted={isCompleted}
				setCompleted={setCompleted}
				setIsManual={setIsManual}
				isManual={isManual}
				onToggleInfo={onToggleInfo}
				isInfoOpened={isInfoOpened}
			/>
		</Row>
	);
};

export default memo(ORom);
